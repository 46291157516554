//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { groupsStore } from '@/store'

export default {
  name: 'ChatMembers',
  components: {
    IconUser: () => import('@/components/UI/icons/IconUser'),
  },
  props: {
    chatId: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: '16px',
    },
  },
  computed: {
    groupMembersCount () {
      return groupsStore.getters.membersCount(this.chatId)
    },
  },
}
